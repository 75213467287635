<template>
    <v-app>
        <template v-if="isInitializing">
            <v-container class="primary fill-height" fluid>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-progress-circular indeterminate color="white" />
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-else>
            <UISystemBar />
            <v-main>
                <router-view />
            </v-main>

            <UISnackbar />
        </template>
    </v-app>
</template>

<script>
    import publicAPI from '_apiPublic'

    import { mapState } from 'vuex'

    export default {
        name: 'App',

        data: () => ({
            isInitializing: true,
        }),

        computed: {
            ...mapState('personalAccount', {
                error: (state) => state.error,
            }),
        },

        created() {
            this.bootstrap()
        },

        methods: {
            async bootstrap() {
                this.listenSoftwareUpdate()

                await this.getToken()
                this.urlCheck()

                await new Promise((resolve) => setTimeout(() => resolve(), 500))

                this.isInitializing = false
            },

            async getToken() {
                await publicAPI.auth.getToken()
            },

            listenSoftwareUpdate() {
                if (process.env.NODE_ENV === 'production') {
                    document.addEventListener('serviceWorkerUpdated', this.onServiceWorkerUpdated, { once: true })

                    navigator.serviceWorker.addEventListener('controllerchange', () => {
                        if (this.$store.state.softwareUpdate.isUpdating) {
                            return
                        }

                        this.$store.commit('softwareUpdate/setUpdating', true)

                        window.location.reload()
                    })
                }
            },

            onServiceWorkerUpdated(event) {
                // Обновление найдено
                this.$store.dispatch('softwareUpdate/setServiceWorkerRegistration', event.detail)

                const serviceWorkerRegistration = this.$store.state.softwareUpdate.serviceWorkerRegistration

                this.$root.pushNotification({
                    text: 'Новая версия ПО',
                    action: {
                        text: 'Обновить',
                        click: () => {
                            this.$store.dispatch('softwareUpdate/update', {
                                serviceWorkerRegistration,
                            })
                        },
                    },
                })
            },

            async urlCheck() {
                let href = window.location.href
                href = href.replace('#/', '')

                const urlParams = new URL(href)

                const companyId = urlParams.searchParams.get('companyId')

                // Поиск номера ЛС доступен всегда,
                // но механизм ограничения оставлен "про запас"
                const ckc = true // urlParams.searchParams.get('ckc')

                if (!companyId) {
                    this.$router.push({ name: 'InvalidLink' })
                } else {
                    this.$store.commit('personalAccount/setCompanyId', companyId)
                    this.$store.commit('personalAccount/setCKC', ckc)
                }
            },
        },
    }
</script>
