import Vue from 'vue'
import Vuex from 'vuex'

import notifications from '_features/notifications/store'
import paymentCardInfo from '_features/paymentCardInfo/store'
import personalAccount from '_features/personalAccount/store'
import premise from '_features/premise/store'
import softwareUpdate from '_features/softwareUpdate/store/store'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        notifications,
        paymentCardInfo,
        personalAccount,
        premise,
        softwareUpdate,
    },
})
