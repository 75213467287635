import API from '_apiPublic'

const actions = {
    async fetchPremise({ commit }, params) {
        commit('fetchPremiseRequest')

        try {
            const premises = await API.premise.getPremises(params)

            commit('fetchPremiseSuccess', { premise: premises[0] })
        } catch (error) {
            commit('fetchPremiseError', error)
            Promise.reject(error)
        }
    },
}

export default actions
