import mutations from './mutations'
import actions from './actions'

const state = {
    paymentCardInfo: null,
    error: null,
    isFetching: false,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
