import { map as _map } from 'lodash'

import { request } from '_apiPublic/client'

import PersonalAccount from '_entities/personalAccount/PersonalAccount'

export const getPersonalAccount = async (companyId, params) => {
    const response = await request('GET', 'account/', params)

    const { results } = response

    if (!results || results.length === 0) {
        throw new Error('Нет данных по лицевым счетам')
    }

    const personalAccounts = _map(results, (result) => {
        const personalAccount = new PersonalAccount(result)

        if (companyId && personalAccount.company?.id === companyId) {
            return personalAccount
        }
    })

    return personalAccounts[0]
}
