import Vue from 'vue'
import VueRouter from 'vue-router'

const InvalidLinkScreen = () => import('_screens/InvalidLinkScreen')
const PaymentDoneScreen = () => import('_screens/PaymentDoneScreen')
const PaymentScreen = () => import('_screens/PaymentScreen')
const PaymentFailScreen = () => import('_screens/PaymentFailScreen')
const SignInScreen = () => import('_screens/SignInScreen')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'SignIn',
        component: SignInScreen,
    },
    {
        path: '/payment',
        name: 'Payment',
        component: PaymentScreen,
    },
    {
        path: '/fail',
        name: 'PaymentFail',
        component: PaymentFailScreen,
    },
    {
        path: '/done',
        name: 'PaymentDone',
        component: PaymentDoneScreen,
    },
    {
        path: '/invalid',
        name: 'InvalidLink',
        component: InvalidLinkScreen,
    },
]

const router = new VueRouter({
    routes,
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
