import * as auth from './auth'
import * as building from './building'
import * as paymentCardInfo from './paymentCardInfo'
import * as personalAccount from './personalAccount'
import * as premise from './premise'

const API = {
    //
    auth,
    building,
    paymentCardInfo,
    personalAccount,
    premise,
}

export default API
