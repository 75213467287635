import colors from 'vuetify/lib/util/colors'

export default {
    background: colors.grey.lighten4,
    primary: {
        lighten6: '#f2e3ef',
        lighten5: '#e0b9d9',
        lighten4: '#cc8ac1',
        lighten3: '#b65ca8',
        lighten2: '#a73897',
        lighten1: '#960f86',
        base: '#8a0a82',
        darken1: '#7a027a',
        darken2: '#6a0072',
        darken3: '#4f0064',
    },
    secondary: '#515459',
    accent: colors.orange.base,
    error: colors.red.base,
    warning: colors.orange.base,
    info: colors.grey.base,
    success: colors.green.base,
}
