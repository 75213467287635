import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'
import light from './theme'

import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faAnalytics as falAnalytics,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faArrowDown as falArrowDown,
    faArrowLeft as falArrowLeft,
    faArrowsAlt as falArrowsAlt,
    faBars as falBars,
    faBell as falBell,
    faBoxesAlt as falBoxesAlt,
    faBuilding as falBuilding,
    faCalendarDay as falCalendarDay,
    faCamera as falCamera,
    faCaretDown as falCaretDown,
    faCaretUp as falCaretUp,
    faChartPie as falChartPie,
    faCheck as falCheck,
    faCircle as falCircle,
    faClipboardList as falClipboardList,
    faClone as falClone,
    faCode as falCode,
    faCog as falCog,
    faEdit as falEdit,
    faEllipsisH as falEllipsisH,
    faEllipsisV as falEllipsisV,
    faExclamationTriangle as falExclamationTriangle,
    faExternalLinkAlt as falExternalLinkAlt,
    faFile as falFile,
    faFolder as falFolder,
    faFolderOpen as falFolderOpen,
    faGripLines as falGripLines,
    faHistory as falHistory,
    faHome as falHome,
    faHouseUser as falHouseUser,
    faList as falList,
    faNewspaper as falNewspaper,
    faMailBulk as falMailBulk,
    faPaperPlane as falPaperPlane,
    faPaperclip as falPaperclip,
    faPencilPaintbrush as falPencilPaintbrush,
    faPhoneAlt as falPhoneAlt,
    faPlus as falPlus,
    faRubleSign as falRubleSign,
    faSave as falSave,
    faSearch as falSearch,
    faSort as falSort,
    faSquare as falSquare,
    faStar as falStar,
    faTimes as falTimes,
    faTools as falTools,
    faTrash as falTrash,
    faUser as falUser,
    faUsers as falUsers,
    faUserCircle as falUserCircle,
    faVideo as falVideo,
} from '@fortawesome/pro-light-svg-icons'
import {
    faCheckSquare as fasCheckSquare,
    faDotCircle as fasDotCircle,
    faStar as fasStar,
} from '@fortawesome/pro-solid-svg-icons'

const makeIconComponentDeclaration = (icon) => {
    faLibrary.add(icon)

    return {
        component: FontAwesomeIcon,
        props: {
            icon: [icon.prefix, icon.iconName],
        },
    }
}

const MY_ICONS = {
    // Vuetify
    complete: '...',
    cancel: '...',
    close: makeIconComponentDeclaration(falTimes),
    delete: makeIconComponentDeclaration(falTimes), // delete (e.g. v-chip close)
    clear: makeIconComponentDeclaration(falTimes),
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: makeIconComponentDeclaration(falExclamationTriangle),
    prev: makeIconComponentDeclaration(falAngleLeft),
    next: makeIconComponentDeclaration(falAngleRight),
    checkboxOn: makeIconComponentDeclaration(fasCheckSquare),
    checkboxOff: makeIconComponentDeclaration(falSquare),
    checkboxIndeterminate: '...',
    delimiter: 'delimiter', // for carousel
    sort: makeIconComponentDeclaration(falArrowDown),
    expand: makeIconComponentDeclaration(falCaretDown),
    menu: makeIconComponentDeclaration(falEllipsisV),
    more: makeIconComponentDeclaration(falEllipsisH),
    subgroup: makeIconComponentDeclaration(falCaretDown),
    dropdown: makeIconComponentDeclaration(falCaretDown),
    radioOn: makeIconComponentDeclaration(fasDotCircle),
    radioOff: makeIconComponentDeclaration(falCircle),
    edit: makeIconComponentDeclaration(falEdit),
    ratingEmpty: makeIconComponentDeclaration(falStar),
    ratingFull: makeIconComponentDeclaration(fasStar),
    ratingHalf: 'ratingHalf',
    loading: 'loading',
    first: 'first',
    last: 'last',
    unfold: makeIconComponentDeclaration(falSort),
    file: makeIconComponentDeclaration(falFile),
    // Other
    analytics: makeIconComponentDeclaration(falAnalytics),
    arrowLeft: makeIconComponentDeclaration(falArrowLeft),
    arrowsAlt: makeIconComponentDeclaration(falArrowsAlt),
    bars: makeIconComponentDeclaration(falBars),
    bell: makeIconComponentDeclaration(falBell),
    boxesAlt: makeIconComponentDeclaration(falBoxesAlt),
    building: makeIconComponentDeclaration(falBuilding),
    calendarDay: makeIconComponentDeclaration(falCalendarDay),
    camera: makeIconComponentDeclaration(falCamera),
    chartPie: makeIconComponentDeclaration(falChartPie),
    caretUp: makeIconComponentDeclaration(falCaretUp),
    check: makeIconComponentDeclaration(falCheck),
    clipboardList: makeIconComponentDeclaration(falClipboardList),
    clone: makeIconComponentDeclaration(falClone),
    code: makeIconComponentDeclaration(falCode),
    cog: makeIconComponentDeclaration(falCog),
    externalLinkAlt: makeIconComponentDeclaration(falExternalLinkAlt),
    folder: makeIconComponentDeclaration(falFolder),
    folderOpen: makeIconComponentDeclaration(falFolderOpen),
    gripLines: makeIconComponentDeclaration(falGripLines),
    history: makeIconComponentDeclaration(falHistory),
    home: makeIconComponentDeclaration(falHome),
    houseUser: makeIconComponentDeclaration(falHouseUser),
    list: makeIconComponentDeclaration(falList),
    newspaper: makeIconComponentDeclaration(falNewspaper),
    mailBulk: makeIconComponentDeclaration(falMailBulk),
    paperPlane: makeIconComponentDeclaration(falPaperPlane),
    paperclip: makeIconComponentDeclaration(falPaperclip),
    pencilPaintbrush: makeIconComponentDeclaration(falPencilPaintbrush),
    phoneAlt: makeIconComponentDeclaration(falPhoneAlt),
    plus: makeIconComponentDeclaration(falPlus),
    rubleSign: makeIconComponentDeclaration(falRubleSign),
    search: makeIconComponentDeclaration(falSearch),
    tools: makeIconComponentDeclaration(falTools),
    trash: makeIconComponentDeclaration(falTrash),
    user: makeIconComponentDeclaration(falUser),
    users: makeIconComponentDeclaration(falUsers),
    userCircle: makeIconComponentDeclaration(falUserCircle),
    video: makeIconComponentDeclaration(falVideo),
    save: makeIconComponentDeclaration(falSave),
}

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { ru },
        current: 'ru',
    },
    icons: {
        values: MY_ICONS,
    },
    theme: {
        themes: { light },
    },
})
