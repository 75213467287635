import Entity from '_entities/base/BaseEntity'

import Company from '_entities/company/Company'

export const PersonalAccountSchema = {
    name: 'PersonalAccount',
    primaryKey: 'id',
    properties: {
        balance: { type: 'float' },
        company: { type: 'Company', entity: Company },
        id: { type: 'string' }, // UUID
        number: { type: 'str' },
        premiseId: { type: 'str' }, // UUID
    },
}

class PersonalAccount extends Entity {
    get schema() {
        return PersonalAccountSchema
    }

    get dataMap() {
        return {
            premise: 'premiseId',
        }
    }

    static fieldLabels = {
        balance: 'Баланс счёта',
        company: 'Компания',
        id: 'ID',
        number: 'Номер лицевого счёта',
        premiseId: 'ID помещения',
    }
}

export default PersonalAccount
