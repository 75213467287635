const mutations = {
    fetchPaymentCardInfo(state) {
        state.isFetching = true
    },

    fetchPaymentCardInfoSuccess(state, paymentCardInfo) {
        state.isFetching = false
        state.error = null
        state.paymentCardInfo = paymentCardInfo
    },

    fetchPaymentCardInfoError(state, error) {
        state.isFetching = false
        state.error = error
    },
}

export default mutations
