const config = {
    production: {
        host: 'https://api.service-company.biz',
    },
    development: {
        host: 'http://dev.service-company.biz:8080',
    },
}

export const host = config[process.env.NODE_ENV].host

export const baseURL = host + '/public_api/'
