import Entity from '_entities/base/BaseEntity'

export const PaymentCardInfoSchema = {
    name: 'PaymentCardInfo',
    properties: {
        formUrl: { type: 'string' },
        orderId: { type: 'string' },
        paymentId: { type: 'int' },
    },
}

class PaymentCardInfo extends Entity {
    get schema() {
        return PaymentCardInfoSchema
    }
}

export default PaymentCardInfo
