import Entity from '_entities/base/BaseEntity'

import Address from '_entities/address/Address'

export const PremiseSchema = {
    name: 'Premise',
    primaryKey: 'id',
    properties: {
        address: { type: 'Address', entity: Address },
        id: { type: 'string' },
        number: { type: 'string' },
    },
}

class Premise extends Entity {
    get schema() {
        return PremiseSchema
    }

    static fieldLabels = {
        address: 'Адрес',
        id: 'ID',
        number: 'Номер',
    }

    get displayName() {
        return this.address?.short || this.address?.full || 'Адрес отсутствует'
    }
}

export default Premise
