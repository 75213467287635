import mutations from './mutations'
import actions from './actions'

const state = {
    isAvailable: false,
    isUpdating: false,
    serviceWorkerRegistration: null,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
