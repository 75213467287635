import { request } from '_apiPublic/client'

export const getToken = async () => {
    const params = {
        username: process.env.VUE_APP_PUBLIC_USERNAME,
        password: process.env.VUE_APP_PUBLIC_PASSWORD,
    }
    try {
        const response = await request('POST', 'user/auth/login/', params)

        const { token } = response

        if (token) {
            localStorage.setItem('publicAccessToken', token)
        } else {
            localStorage.removeItem('publicAccessToken')
        }
    } catch (e) {
        localStorage.removeItem('publicAccessToken')
    }
}
