const mutations = {
    fetchPersonalAccountRequest(state) {
        state.isFetching = true
    },

    fetchPersonalAccountSuccess(state, { personalAccount }) {
        state.isFetching = false
        state.error = null
        state.personalAccount = personalAccount
    },

    fetchPersonalAccountError(state, error) {
        state.isFetching = false
        state.error = error
    },

    clearPersonalAccount(state) {
        state.personalAccount = null
    },

    setCompanyId(state, companyId) {
        state.companyId = companyId
    },

    setCKC (state, ckc) {
        state.ckc = ckc
    },

    setPersonalAccountId(state, personalAccountId) {
        state.personalAccountId = personalAccountId
    },

    setAccountNumber(state, accountNumber) {
        state.accountNumber = accountNumber
    },
}

export default mutations
