import mutations from './mutations'
import actions from './actions'

const state = {
    accountNumber: null,
    companyId: null,
    ckc: null,
    isFetching: false,
    error: null,
    personalAccount: null,
    personalAccountId: null,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
