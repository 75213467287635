import { map as _map } from 'lodash'

import { request } from '_apiPublic/client'

import Premise from '_entities/premise/Premise'

export const getPremises = async (params) => {
    const response = await request('GET', 'premise/', params)

    const { results } = response

    if (!results || results.length === 0) {
        throw new Error('Нет данных по помещениям')
    }

    const premises = _map(results, (result) => new Premise(result))

    return premises
}
