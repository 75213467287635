import API from '_apiPublic'

const actions = {
    async fetchPersonalAccount({ commit }, { companyId, params }) {
        commit('fetchPersonalAccountRequest')

        const _params = {
            ...params,
            is_active: true,
        }

        try {
            const personalAccount = await API.personalAccount.getPersonalAccount(companyId, _params)

            commit('fetchPersonalAccountSuccess', { personalAccount })
        } catch (error) {
            commit('fetchPersonalAccountError', error)
            Promise.reject(error)
        }
    },
}

export default actions
