const mutations = {
    fetchPremiseRequest(state) {
        state.isFetching = true
    },

    fetchPremiseSuccess(state, { premise }) {
        state.isFetching = false
        state.error = null
        state.premise = premise
    },

    fetchPremiseError(state, error) {
        state.isFetching = false
        state.error = error
    },

    clearPremise(state) {
        state.premise = null
    },
}

export default mutations
