import Entity from '_entities/base/BaseEntity'

export const BuildingSchema = {
    name: 'Building',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        shortName: { type: 'string' },
    },
}

class Building extends Entity {
    get schema () {
        return BuildingSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Наименование',
    }

    get displayName () {
        return this.shortName || this.name
    }
}

export default Building
