import API from '_apiPublic'

import PaymentCardInfo from '_entities/paymentCardInfo/PaymentCardInfo'

const actions = {
    async getPaymentCardInfo({ commit }, { premiseId, params }) {
        commit('fetchPaymentCardInfo')

        try {
            const response = await API.paymentCardInfo.getPaymentCardInfo(premiseId, params)

            const paymentCardInfo = new PaymentCardInfo(response)

            commit('fetchPaymentCardInfoSuccess', paymentCardInfo)
        } catch (error) {
            commit('fetchPaymentCardInfoError', error)
            Promise.reject(error)
        }
    },
}

export default actions
